import React from "react";
import Tile from "./Tile";

import styles from "./Portfolio.module.scss";

const Portfolio = ({ projects }) => {
  return (
    <div className={styles.self}>
      {projects
        .sort((a, b) => a.node.sorting_id - b.node.sorting_id)
        .map(project => (
          <Tile project={project} key={`project__${project.node.strapiId}`} />
        ))}
    </div>
  );
};

export default Portfolio;
