import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Portfolio from "../components/Portfolio";

export const query = graphql`
  query {
    allStrapiSeo {
      edges {
        node {
          description
          image {
            publicURL
          }
        }
      }
    }
    allStrapiProject {
      edges {
        node {
          strapiId
          name
          link
          sorting_id
          active
          menu_image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 475) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout
    seoOptions={{
      title: "Portfolio",
      description: data.allStrapiSeo.edges[0].node.description,
      ogImage: data.allStrapiSeo.edges[0].node.image.publicURL,
    }}
  >
    <Portfolio projects={data.allStrapiProject.edges} />
  </Layout>
);

export default IndexPage;
